<template>
    <!-- Wallet Card -->
    <div class="section pt-2">
        <div class="wallet-card">
            <!-- Wallet Footer -->
            <div class="wallet-footer">
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper">
                            <ion-icon name="person-add-outline"></ion-icon>
                        </div>
                        <strong>New Admin</strong>
                    </a>
                </div>
                <div class="item">
                    <router-link :to="'/org/' + (user_level) + '/' + user_level_id + '/admin-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper">
                                <ion-icon name="list-outline"></ion-icon>
                            </div>
                            <strong>Admin List</strong>
                        </a>
                    </router-link>
                </div>
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper">
                            <ion-icon name="people-outline"></ion-icon>
                        </div>
                        <strong>Office Bearers</strong>
                    </a>
                </div>
            </div>
            <div class="wallet-footer pt-2">
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper">
                            <ion-icon name="walk-outline"></ion-icon>
                        </div>
                        <strong>Meetings</strong>
                    </a>
                </div>
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper">
                            <ion-icon name="receipt-outline"></ion-icon>
                        </div>
                        <strong>Reports</strong>
                    </a>
                </div>
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper">
                            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                        </div>
                        <strong>Others</strong>
                    </a>
                </div>
            </div>
            <!-- * Wallet Footer -->
        </div>
    </div>
    <!-- Wallet Card -->

    <div class="listview-title mt-2">{{ sub_level_list_name }}
    </div>
    <div class="section text-center" v-if="loading">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <ul class="listview image-listview inset text">
        <li v-for="sub_level in sub_levels" v-bind:key="sub_level.id">
            <router-link :to="'org/' + (user_level + 1) + '/' + sub_level.id">
                <a href="javascript:void(0)" class="item">
                    <!-- <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" class="image"> -->
                    <div class="in">
                        <div>
                            <!-- <header>User</header> -->
                            <b class="list-title">{{ sub_level.name }}</b>
                            <footer v-if="user_level == 1"><span class="badge badge-primary">Total zones : {{ sub_level.zones_count }}</span> <span class="badge" v-bind:class="sub_level.badge_color">Admin alloted :{{sub_level.admins_count}}</span></footer>
                            <footer v-if="user_level == 2"><span class="badge badge-primary">Total units : {{ sub_level.units_count }}</span> <span class="badge" v-bind:class="sub_level.badge_color">Admin alloted :{{sub_level.admins_count}}</span></footer>
                        </div>
                        
                        <!-- <span class="text-muted">View</span> -->
                    </div>
                </a>
            </router-link>
        </li>
    </ul>
</template>
<script>
import UserService from "../services/user.service";
export default {
    name: 'Organization',
    components: {
    },
    data() {
        return {
            loading: false,
            message: "",
            user_level: "",
            sub_levels: [],
            sub_level_list_name: "",
            user_level_id: "",
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = this.currentUser.user_level;
            if (this.user_level == 1) {
                this.sub_level_list_name = 'District List';
                this.user_level_id = 0;
                this.$root.updateParent("State");
            } else if (this.user_level == 2) {
                this.sub_level_list_name = 'Zone List';
                this.user_level_id = this.currentUser.district_id;
                this.$root.updateParent("District");
            }
            else if (this.user_level == 3) {
                this.sub_level_list_name = 'Unit List';
                this.user_level_id = this.currentUser.zone_id;
                this.$root.updateParent("Unit");
            }
            else if (this.user_level == 4) {
                this.sub_level_list_name = 'Members List';
                this.user_level_id = this.currentUser.unit_id;
                this.$root.updateParent("Unit");
            }
            this.getSubLevels();
        }
    },
    methods: {
        getSubLevels() {
            this.loading = true;
            // var user_level_per_id = null;
            if (this.user_level == 2) {
                this.currentUser.district_id;
            } else if (this.user_level == 3) {
                this.currentUser.zone_id;
            } else if (this.user_level == 4) {
                this.currentUser.unit_id;
            }
            let data = {
                sub_level_type: this.user_level + 1,
                id: this.user_level_id
            }
            UserService.getSubLevels('get-sub-levels', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.sub_levels = response.data.data.list;
                        this.$root.updateParent(response.data.data.page_title);
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        clickSublevel(sub_level_id) {
            this.$router.push('/level-2-org/' + (this.user_level + 1) + '/' + sub_level_id);
        }
    },
};
</script>
<style scoped>
.list-title{
    font-size: 16px;
}
</style>