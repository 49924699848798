import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import VueSweetalert2 from 'vue-sweetalert2';
const options = {
  confirmButtonColor: '#1a418c',
  cancelButtonColor: '#ff7674',
};
import 'sweetalert2/dist/sweetalert2.min.css';
import { FontAwesomeIcon } from './plugins/font-awesome'
import Toaster from "@incuca/vue3-toaster";
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './registerServiceWorker'
createApp(App)
  .use(router)
  .use(VueSweetalert2,options)
  .use(Toaster)
  .use(VueSplide)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");


