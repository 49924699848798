<template>
    <!-- App Bottom Menu -->
    <div class="appBottomMenu">
        <a href="javascript:void(0)" class="item" v-bind:class="{ active: ishomeActive }" @click="setActiveMenu('Home','/home')">
            <div class="col">
                <ion-icon name="home-outline"></ion-icon>
                <strong>Home</strong>
            </div>
        </a>
        <a href="javascript:void(0)" class="item" v-bind:class="{ active: isOrgActive }" @click="setActiveMenu('ORG','/org')">
            <div class="col">
                <ion-icon name="apps-outline"></ion-icon>
                <strong>ORG</strong>
            </div>
        </a>
        <a href="javascript:void(0)" class="item" v-bind:class="{ active: isProfileActive }" @click="setActiveMenu('Profile','/my-profile')">
            <div class="col">
                <ion-icon name="person-outline"></ion-icon>
                <strong>Profile</strong>
            </div>
        </a>
        <a href="javascript:void(0)" class="item" v-bind:class="{ active: isHelpDeskActive }"
            @click="setActiveMenu('HelpDesk','/help-desk')">
            <div class="col">
                <ion-icon name="help-outline"></ion-icon>
                <strong>Help Desk</strong>
            </div>
        </a>
        <!-- <a href="app-settings.html" class="item">
            <div class="col">
                <ion-icon name="settings-outline"></ion-icon>
                <strong>Settings</strong>
            </div>
        </a> -->
    </div>
    <!-- * App Bottom Menu -->
</template>
<script>
export default {
    data() {
        return {
            ishomeActive: false,
            isProfileActive: false,
            isOrgActive: false,
            isHelpDeskActive: false
        }
    },
    methods: {
        setActiveMenu(item,route) {
            localStorage.activeMenu = item;
            this.$router.push(route);
            this.setActiveStatus();
        },
        setActiveStatus() {
            this.ishomeActive = false,
            this.isProfileActive = false,
            this.isOrgActive = false,
            this.isHelpDeskActive = false

            if (localStorage.activeMenu == 'Home') {
                this.ishomeActive = true;
                this.$root.updateParent('Home');
            } else if (localStorage.activeMenu == 'Profile') {
                this.isProfileActive = true;
                this.$root.updateParent('My Profile');
            } else if (localStorage.activeMenu == 'ORG') {
                this.isOrgActive = true;
                this.$root.updateParent(null);
            } else if (localStorage.activeMenu == 'HelpDesk') {
                this.$root.updateParent('Help Desk');
                this.isHelpDeskActive = true;
            } else {
                this.ishomeActive = true;
            }
        },
        setActiveStatusBack() {
            if (this.$route.name == 'Home') {
                localStorage.activeMenu = 'Home';
            } else if (this.$route.name == 'Profile') {
                localStorage.activeMenu = 'Profile';
            } else if (this.$route.name == 'ORG') {
                localStorage.activeMenu = 'ORG';
            } else if (this.$route.name == 'Help Desk') {
                localStorage.activeMenu = 'HelpDesk';
            }
        }
    },
    mounted() {
        this.setActiveStatusBack()
        this.setActiveStatus();
    }
};
</script>