<template>
    <!-- App Capsule -->
    <div id="appCapsule">
        <div class="section mt-2 text-center">
            <img src="/assets/icons_blue/icon-192x192.png" width="100">
        </div>
        <div class="section mt-2 text-center">
            <h1>Login</h1>
            <h4>Enter your mobile number.</h4>
        </div>
        <div class="section mb-5 p-2">
            <Form @submit="handleLogin" :validation-schema="schema">
                <div class="card">
                    <div class="card-body pb-1 row">
                        <label class="label" for="country_code">Mobile Number</label>
                        <div class="form-group boxed" style="width:30% !important;">
                            <div class="input-wrapper">
                                <Field as="select" class="form-control custom-select" v-model="country_code"
                                    name="country_code" id="select4">
                                    <option value="91" selected="selected">+91</option>
                                    <option value="971">+971</option>
                                </Field>
                            </div>
                        </div>
                        <div class="form-group boxed mobile-field" style="width:68% !important; margin-left: 2%;">
                            <div class="input-wrapper">
                                <Field name="username" type="number" id="username" class="form-control"
                                    placeholder="0000000000" v-model="mobile_number" oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 11); } if(this.value.length > 10){ this.value = this.value.slice(0, 10);}"/>
                            </div>
                        </div>
                        <ErrorMessage name="username" class="error-feedback" />
                        <div class="form-group" v-if="message">
                            <div v-if="message" class="error-feedback">
                                {{ message }}
                            </div>
                        </div>
                        <label class="label" for="password" v-if="login_with_pin">Pin</label>
                        <div class="form-group boxed" v-if="login_with_pin">
                            <Field name="password" type="password" class="form-control" id="smscode" placeholder=""
                                oninput="javascript: if (this.value.length > 4) this.value = this.value.slice(0, 4);"/>
                        </div>
                        <!-- <div class="form-group boxed" style="width:100% !important;">
                            <div class="input-wrapper">
                                <label class="label" for="password">Password</label>
                                <Field name="password" type="password" class="form-control" />
                            </div>
                        </div> -->
                        <!-- <ErrorMessage name="password" class="error-feedback" v-if="login_with_pin" /> -->
                    </div>
                </div>
                <div class="form-links mt-2">
                    <div>
                        <!-- <a href="app-register.html">Send OTP</a> -->
                    </div>
                    <div>
                        <!-- <router-link :to="'/otp'">Get Pin Number as SMS</router-link> -->
                    </div>
                </div>
                <div class="transparent row pt-3" v-if="!login_with_pin">
                    <div class="col-6">
                        <a href="javascript:void(0)" @click="loginWithPin"
                            class="btn btn-lg btn-outline-secondary btn-block">Login With Pin</a>
                    </div>
                    <div class="col-6">
                        <a href="javascript:void(0)" @click="loginWithSms"
                            class="btn btn-lg btn-primary btn-block">Login With SMS</a>
                    </div>
                </div>
                <div class="transparent pt-3" v-if="login_with_pin">
                    <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Login</span>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <!-- * App Capsule -->
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
    name: "Login",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const schema = yup.object().shape({
            username: yup.string().min(10, 'Must be 10 digits').required('Phone number is required!'),
            password: yup.string().required("Pin is required!"),
        });

        return {
            loading: false,
            message: "",
            schema,
            country_code: "91",
            login_with_pin: false,
            mobile_number: '',
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            localStorage.activeMenu = 'Home';
            this.$router.push("/home");
        }
    },
    methods: {
        handleLogin(user) {
            user.username = user.country_code + user.username;
            this.loading = true;
            this.$store.dispatch("auth/login", user).then(
                () => {
                    localStorage.activeMenu = 'Home';
                    location.replace('/')
                },
                (error) => {
                    this.loading = false;
                    var error_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();

                    this.message = error_message == 'Unauthorized' ? 'Mobile number or Pin is invalid' : error_message;

                }
            );
        },
        loginWithPin() {
            this.login_with_pin = true;
        },
        loginWithSms() {
            localStorage.started_country_code = this.country_code;
            localStorage.started_mobile_number = this.mobile_number;
            this.$router.push('/otp');
        }
    },
};
</script>

<style scoped>
.error-feedback {
    color: red;
}

#username {
    margin-top: 0px;
}

.label {
    margin-left: -10px;
    margin-bottom: 0px;
}
</style>
