import { createWebHashHistory, createRouter } from "vue-router";
import Home from "./views/MyHome.vue";
import Login from '@/views/Login.vue'
import Register from "./components/Register.vue";
import MyProfile from '@/views/MyProfile.vue'
import HelpDesk from '@/views/HelpDesk.vue'
import Org from '@/views/Org.vue'
import OrgAdminList from '@/views/OrgAdminList.vue'
import Level2Org from '@/views/Level2Org.vue'
import OtpVerify from '@/views/OtpVerify.vue'
import SetPassword from '@/views/SetPassword.vue'

// lazy-loaded
const Profile = () => import("@/views/MyProfile.vue")
const BoardAdmin = () => import("./components/BoardAdmin.vue")
const BoardModerator = () => import("./components/BoardModerator.vue")
const BoardUser = () => import("./components/BoardUser.vue")

const routes = [

  { path: '/login', name: 'Login', component: Login },
  { path: '/', name: 'Login', component: Login },
  { path: '/home', name: 'Home', component: Home },
  { path: '/my-profile', name: 'My Profile', component: MyProfile },
  { path: '/help-desk', name: 'Help Desk', component: HelpDesk },
  { path: '/org', name: 'ORG', component: Org },
  { path: '/org/:sub_level/:id', name: 'Sub ORG', component: Level2Org },
  { path: '/org/:sub_level/:id/admin-list', name: 'Admin List', component: OrgAdminList },

  { path: '/otp', name: 'OTP', component: OtpVerify },
  { path: '/set-password', name: 'Reset Pin', component: SetPassword },

  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "Profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: BoardAdmin,
  },
  {
    path: "/mod",
    name: "moderator",
    // lazy-loaded
    component: BoardModerator,
  },
  {
    path: "/user",
    name: "user",
    // lazy-loaded
    component: BoardUser,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });

export default router;