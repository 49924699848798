<template>
    <!-- Wallet Card -->
    <div class="section pt-2">
        <div class="wallet-card">
            <div class="wallet-footer">
                <div class="item">
                    <a href="javascript:void(0)" class="button" @click="showAddAdminModel">
                        <div class="icon-wrapper">
                            <ion-icon name="person-add-outline"></ion-icon>
                        </div>
                        <strong>New Admin</strong>
                    </a>
                </div>
                <div class="item">
                    <router-link :to="'/org/' + (user_level) + '/' + $route.params.id + '/admin-list'">
                        <a href="javascript:void(0)">
                            <div class="icon-wrapper">
                                <ion-icon name="list-outline"></ion-icon>
                            </div>
                            <strong>Admin List</strong>
                        </a>
                    </router-link>
                </div>
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper">
                            <ion-icon name="people-outline"></ion-icon>
                        </div>
                        <strong>Office Bearers</strong>
                    </a>
                </div>
            </div>
            <div class="wallet-footer pt-2">
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper">
                            <ion-icon name="walk-outline"></ion-icon>
                        </div>
                        <strong>Meetings</strong>
                    </a>
                </div>
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper">
                            <ion-icon name="receipt-outline"></ion-icon>
                        </div>
                        <strong>Reports</strong>
                    </a>
                </div>
                <div class="item">
                    <a href="javascript:void(0)">
                        <div class="icon-wrapper">
                            <ion-icon name="ellipsis-horizontal-outline"></ion-icon>
                        </div>
                        <strong>Others</strong>
                    </a>
                </div>
            </div>
            <!-- * Wallet Footer -->
        </div>
    </div>
    <!-- Wallet Card -->
    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Add Admin</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group basic">
                                <label class="label" for="Name">Name</label>
                                <div class="form-group boxed">
                                    <Field name="name" type="text" class="form-control" id="name"
                                        placeholder="Enter Name" />
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                                <div class="form-group" v-if="message">
                                    <div v-if="message" class="error-feedback">
                                        {{ message }}
                                    </div>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper row">
                                    <label class="label" for="country_code">Mobile Number</label>
                                    <div class="form-group boxed" style="width:30% !important;">
                                        <div class="input-wrapper">
                                            <Field as="select" class="form-control custom-select" v-model="country_code"
                                                name="country_code" id="select4">
                                                <option value="91" selected="selected">+91</option>
                                                <option value="971">+971</option>
                                            </Field>
                                        </div>
                                    </div>
                                    <div class="form-group boxed mobile-field"
                                        style="width:68% !important; margin-left: 2%;">
                                        <div class="input-wrapper">
                                            <Field name="mobile_number" type="number" id="mobile_number"
                                                class="form-control" placeholder="" oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 11); } if (this.value.length > 10) { this.value = this.value.slice(0, 10); }"/>
                                        </div>
                                    </div>
                                    <ErrorMessage name="mobile_number" class="error-feedback" />
                                </div>
                            </div>
                            <div class="form-group basic">
                                <label class="label" for="wing">Wing</label>
                                <div class="form-group boxed">
                                    <div class="input-wrapper">
                                        <Field as="select" class="form-control custom-select" v-model="wing" name="wing"
                                            id="select4">
                                            <option selected>Select Wing</option>
                                            <option value="1">Wisdom</option>
                                            <option value="2">Youth</option>
                                            <option value="3">Students</option>

                                            <option value="4">Womens</option>
                                            <!-- <option value="5">Balavedi</option> -->
                                            <option value="6" v-if="user_level == 3 || user_level == 4">Girls</option>

                                        </Field>
                                    </div>
                                </div>
                                <ErrorMessage name="wing" class="error-feedback" />
                            </div>
                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- * AddAdminModel -->

    <div class="listview-title mt-2">{{ sub_level_list_name }}</div>
    <div class="section text-center" v-if="loading_list && user_level != 4">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <ul class="listview image-listview inset text" v-if="!loading_list && user_level != 4">
        <li v-for="sub_level in sub_levels" v-bind:key="sub_level.id">
            <router-link :to="'/org/' + (user_level + 1) + '/' + sub_level.id">
                <a class="item">
                    <!-- <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" class="image"> -->
                    <div class="in">
                        <div>
                            <!-- <header>User</header> -->
                            <b class="list-title">{{ sub_level.name }}</b>
                            <footer v-if="user_level == 1"><span class="badge badge-primary">Total zones : {{ sub_level.zones_count }}</span> <span class="badge" v-bind:class="sub_level.badge_color">Admin alloted :{{sub_level.admins_count}}</span></footer>
                            <footer v-if="user_level == 2"><span class="badge badge-primary">Total units : {{ sub_level.units_count }}</span> <span class="badge" v-bind:class="sub_level.badge_color">Admin alloted :{{sub_level.admins_count}}</span></footer>
                        </div>
                        <!-- <span class="text-muted">View</span> -->
                    </div>
                </a>
            </router-link>
        </li>
    </ul>
</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service";
import $ from 'jquery'
export default {
    name: 'Organization',
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const nameRegex = /^[a-zA-Z0-9&._-\s]*$/g;
        const schema = yup.object().shape({
            name: yup.string().matches(nameRegex, "Please enter name in English").required("Name is required!"),
            mobile_number: yup.string().min(10, 'Must be 10 digits').required("Mobile Number is required!"),
            wing: yup.string().required("Wing is required!"),
        });

        return {
            loading: false,
            loading_list: false,
            message: "",
            schema,
            country_code: "91",
            wing: "",
            user_level: "",
            sub_levels: [],
            admin_list: []
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = parseInt(this.$route.params.sub_level);
            if (this.user_level == 1) {
                this.sub_level_list_name = 'District List';
            } else if (this.user_level == 2) {
                this.sub_level_list_name = 'Zone List';
            }
            else if (this.user_level == 3) {
                this.sub_level_list_name = 'Unit List';
            }
            // if (this.user_level != 4) {
                this.getSubLevels();
            // }
        }
        
        window.onpopstate = function () {
            if($('body').hasClass('modal-open')){
                location.reload();
            }
        };
    },
    methods: {

        addAdmin(user) {
            user.username = user.country_code + user.mobile_number;
            user.user_level = this.user_level;
            user.added_by = this.currentUser.id;
            if (user.user_level == 2) {
                user.district_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 3) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = parseInt(this.$route.params.id);
            }
            if (user.user_level == 4) {
                user.district_id = this.currentUser.district_id;
                user.zone_id = this.currentUser.zone_id;
                user.unit_id = parseInt(this.$route.params.id);
            }

            this.loading = true;
            UserService.addAdmin('register', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide');
                        this.$swal('Admin Saved!', '', 'seccess');
                        
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.message = response.data.message;
                        this.loading = false;
                    }
                    else {

                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    console.log((error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString());
                }
            );

        },

        getSubLevels() {
            this.loading_list = true;
            let data = {
                sub_level_type: this.user_level + 1,
                id: parseInt(this.$route.params.id)
            }
            UserService.getSubLevels('get-sub-levels', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.sub_levels = response.data.data.list;
                        this.$root.updateParent(response.data.data.page_title);
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = true;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminList() {
            let data = {
                id: parseInt(this.$route.params.id)
            }
            UserService.getAdminList('get-admin-list', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.admin_list = response.data.data.list;
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        showAddAdminModel() {
            $('#addAdminModel').modal('show');
        },
        clickSublevel(sub_level_id) {
            this.$router.push('/org/' + (this.user_level + 1) + '/' + sub_level_id);
        },
    },
};
</script>
<style scoped>
.error-feedback {
    color: red;
}
.list-title{
    font-size: 16px;
}
</style>