<template>
    <!-- App Capsule -->
    <div id="appCapsule">
        <div class="section mt-2 text-center">
            <img src="/assets/icons_blue/icon-192x192.png" width="100">
        </div>
        <div class="section mt-2 text-center">
            <h1>Login</h1>
            <h4>Enter your mobile number to login.</h4>
        </div>
        <div class="section mb-5 p-2">
            <Form @submit="sendOtp" :validation-schema="get_otp_form">
                <div class="card">
                    <div class="card-body pb-1 row">
                        <label class="label" for="country_code">Mobile Number</label>
                        <div class="form-group boxed" style="width:30% !important;">
                            <div class="input-wrapper">
                                <Field as="select" class="form-control custom-select" v-model="country_code"
                                    name="country_code" id="select4">
                                    <option value="91" selected="selected">+91</option>
                                    <option value="971">+971</option>
                                </Field>
                            </div>
                        </div>
                        <div class="form-group boxed" style="width:68% !important; margin-left: 2%;">
                            <div class="input-wrapper">
                                <Field name="mobile_number" type="number" maxlength="10" id="mobile_number"
                                    class="form-control" placeholder="0000000000" v-model="mobile_number" oninput="javascript: this.value.replace(/[\s\/]/g,''); if(this.value.charAt(0) == 0){ this.value = this.value.slice(1, 11); } if(this.value.length > 10){ this.value = this.value.slice(0, 10);}" />
                            </div>
                        </div>
                        <ErrorMessage name="mobile_number" class="error-feedback" />
                        <label class="label" for="password" v-if="otp_sent">Pin</label>
                        <div class="form-group boxed" v-if="otp_sent">
                            <Field name="otp" type="number" class="form-control" id="smscode" placeholder=""
                                oninput="javascript: if (this.value.length > 4) this.value = this.value.slice(0, 4);"/>
                        </div>
                        <ErrorMessage name="otp" class="error-feedback" v-if="otp_sent" />
                        <div class="form-group" v-if="message">
                            <div v-if="message" class="error-feedback">
                                {{ message }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-links mt-2">
                    <div>
                        <p v-if="countDown && countDown > 0">Resend Pin ({{countDown}})</p>
                        <div v-if="otp_send_link">
                         <a href="javascript:void(0)"  @click="resendOtp">Resend Pin</a> <span>Or <a href="https://wa.me/919526221177">Contact Whatsapp</a></span>
                        </div>
                        
                    </div>
                    <div><a href="/">Click Here Already Have Pin.</a></div>
                </div>
                <div class="transparent pt-3">
                    <button class="btn btn-primary btn-block btn-lg" :disabled="loading" v-if="!otp_sent" id="buttonSubmit">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Click Here to Get Pin</span>
                    </button>
                    <button class="btn btn-primary btn-block btn-lg" :disabled="loading" v-if="otp_sent">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Verify</span>
                    </button>
                </div>
            </Form>
        </div>
    </div>
    <!-- * App Capsule -->


    <!-- App Capsule -->
    <!-- <div id="appCapsule" v-if="otp_sent">
        <div class="section mt-2 text-center">
            <img src="/assets/img/wisdom_logo.png" width="100">
        </div>
        <div class="section mt-2 text-center">
            <h1>Pin Verification</h1>
            <h4>Enter 4 digit pin number</h4>
        </div>
        <div class="section mb-5 p-2">
            <Form @submit="verifyOtp" :validation-schema="verify_otp_form">
                <div class="form-group basic">
                    <Field name="otp" type="text" class="form-control verification-input" id="smscode"
                        placeholder="••••" maxlength="4" />
                </div>
                <ErrorMessage name="otp" class="error-feedback" />
                <div class="form-button-group transparent">
                    <button class="btn btn-primary btn-block btn-lg" :disabled="loading">
                        <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                        <span>Verify</span>
                    </button>
                </div>
            </form>
        </div>

    </div> -->
    <!-- * App Capsule -->

</template>
<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import UserService from "../services/user.service";
export default {
    name: "Login",
    components: {
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        const get_otp_form = yup.object().shape({
            mobile_number: yup.string().min(10, 'Must be 10 digits').required("Mobile Number is required!"),
            otp: this.otp_sent ? yup.string().required("OTP is required!") : ''
        })
        return {
            loading: false,
            message: "",
            get_otp_form,
            country_code: localStorage.started_country_code ? localStorage.started_country_code : "91",
            mobile_number: localStorage.started_mobile_number ? localStorage.started_mobile_number : "",
            otp_sent: false,
            content: [],
            countDown: null,
            otp_send_link:null,
            form_data:[]
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    created() {
        if (this.loggedIn) {
            this.$router.push("/home");
        }
    },
    methods: {
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1
                    if(this.countDown == 0){
                        this.otp_send_link = true;
                    }
                    this.countDownTimer()
                    
                }, 1000)
            }
        },
        sendOtp(user) {
            this.message = null;
            this.form_data = user;
            this.otp_send_link = false;
            this.loading = true;
            if (!this.otp_sent) {
                UserService.getOtp('get-otp', user).then(
                    (response) => {
                        console.log(response);
                        if (response.data.status == 'success') {
                            this.countDown = 30;
                            this.countDownTimer();
                            this.content = response.data.data;
                            // this.message = response.data.data.message;
                            this.$swal('', 'Pin number sent to your mobile number. Please check your SMS', 'seccess');
                            this.otp_sent = true;
                            this.loading = false;
                        }else if(response.data.status == 'error'){
                            this.message = response.data.message;
                        } else {
                            this.loading = false;
                        }
                    },
                    (error) => {
                        this.loading = false;
                        this.message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            } else {
                UserService.verifyOtp('verify-otp', user).then(
                    (response) => {
                        this.loading = false;
                        if (response.data.status == 'success') {
                            this.content = response.data.user;
                            localStorage.setItem('user', JSON.stringify(response.data.user));
                            location.replace('/')
                        }
                        else if(response.data.status == 'error') {
                            this.message = response.data.message;
                        }

                    },
                    (error) => {
                        this.loading = false;
                        this.message =
                            (error.response &&
                                error.response.data &&
                                error.response.data.message) ||
                            error.message ||
                            error.toString();
                    }
                );
            }

        },
        resendOtp(){
            this.otp_sent = false;
            this.sendOtp(this.form_data);
        }
    },
};
</script>
<style scoped>
.error-feedback {
    color: red;
}

#mobile_number {
    margin-top: 0px;
}

.label {
    margin-left: -10px;
    margin-bottom: 0px;
}
</style>
