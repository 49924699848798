<template>
            <div class="section mt-2 text-center">
            <div class="card">
                <div class="card-body pt-0 pb-0">
                    <img src="assets/logo.png" alt="image" class="imaged w-50 ">
                    <h2 class="mt-0">സംശയങ്ങൾ <br> </h2>
                </div>
            </div>
        </div>

        <div class="section inset mt-2">
            <div class="accordion" id="accordionExample1">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion01">
                            1. Wisdom Guide മൊബൈൽ ആപ്പ് എവിടെ നിന്ന് ലഭിക്കും
                        </button>
                    </h2>
                    <div id="accordion01" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         http://guide.wisdomislam.org/  എന്ന ലിങ്കിൽ കയറിയാൽ ആർക്കും ആപ്പ് ലഭിക്കും. എന്നാൽ ആഗസ്ത് 31 വരെ അഡ്മിന്മാർക്ക് മാത്രമാണ് ലോഗിൻ ചെയ്‌തു കയറാനുള്ള പെർമിഷൻ ഉള്ളൂ.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion02">
                           2. എല്ലാ മൊബൈൽ ഫോണുകളിലും ആപ്പ് ലഭിക്കുമോ?
                        </button>
                    </h2>
                    <div id="accordion02" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                           ലഭിക്കും ആൻഡ്രോയിഡ്, ios, വിൻഡോസ് തുടങ്ങി എല്ലാ മൊബൈൽ ഫോണുകളിലും കമ്പ്യൂട്ടറുകളിലും ലാപ്ടോപ്പുകളിലും wisdom guide ഉപയോഗിക്കാൻ സാധിക്കും.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion03">
                            3. ലിങ്കിൽ നിന്ന് എങ്ങനെയാണ് ആപ്പ് ഉപയോഗിക്കുക
                        </button>
                    </h2>
                    <div id="accordion03" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                           ലിങ്കിൽ ക്ലിക്ക് ചെയ്താൽ ലിങ്ക് നിങ്ങളുടെ ഡിവൈസിലെ ബ്രൗസറിൽ (ഉദാ: google chrome, etc.) ആണ് തുറക്കുക. ഇവിടെ നിന്നും ഒരു മൊബൈൽ ആപ്പ് ഉപയോഗിക്കുന്ന രീതിയിൽ നിങ്ങളുടെ ഫോണിലേക്ക് ഇൻസ്റ്റാൾ ചെയ്യാൻ സാധിക്കും. അതിനായി ബ്രൗസറിൽ ഉള്ള add to home എന്ന ഓപ്ഷൻ ഉപയോഗിക്കുക. കൂടുതൽ മനസിലാക്കാൻ ഈ വീഡിയോ കാണുക.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion04">
                           4. ലോഗിൻ ചെയ്യുന്നവർ ശ്രദ്ധിക്കേണ്ട കാര്യങ്ങൾ എന്തൊക്കെ? 
                        </button>
                    </h2>
                    <div id="accordion04" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                           നിങ്ങളുടെ മൊബൈൽ നമ്പർ ഉപയോഗിച്ചാണ് ലോഗിൻ ചെയ്യേണ്ടത്. ആദ്യം ലോഗിൻ ചെയ്യുന്നവർക്ക് SMS ആയി ഒരു പിൻ നമ്പർ ലഭിക്കും. ഈ PIN നമ്പർ മറക്കാതെ ഓർത്തു വെക്കുക. മറ്റൊരു ഡിവൈസിൽ ലോഗിൻ ചെയ്യുമ്പോഴും നിലവിലെ ഡിവൈസിൽ നിന്ന് ലോഗൗട്ട് ചെയ്ത ശേഷം വീണ്ടും ലോഗിൻ ചെയ്യുമ്പോൾ ഒക്കെ ഈ PIN നമ്പർ ആണ് കൊടുക്കേണ്ടത്.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion05">
                           5. ഈ പിൻ നമ്പർ മാറ്റാൻ സാധിക്കുമോ?
                        </button>
                    </h2>
                    <div id="accordion05" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                          നിലവിൽ പിൻ നമ്പർ ഇഷ്ടാനുസരണം സെറ്റ് ചെയ്യാനുള്ള ഓപ്ഷൻ Profile എന്ന ഭാഗത്ത് ഉണ്ട്. അത് ഉപയോഗിച്ച് PIN നമ്പർ മാറ്റാവുന്നതാണ്.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion06">
                            6. പിൻ നമ്പർ മറന്നുപോയവർ എന്ത് ചെയ്യണം?
                        </button>
                    </h2>
                    <div id="accordion06" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         ആദ്യ തവണ ചെയ്ത പോലെ മൊബൈൽ നമ്പർ നൽകി വീണ്ടും SMS വഴി PIN നമ്പർ ലഭിക്കാനുള്ള സംവിധാനം ആപ്പിൽ ഉണ്ട്. അത് ഉപയോഗിക്കുക.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion07">
                           7. ഇലക്ഷൻ സമയത്ത് വിസ്ഡം ഗൈഡ് ആർക്കൊക്കെ ഉപയോഗിക്കാൻ കഴിയും?
                        </button>
                    </h2>
                    <div id="accordion07" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                           ഇലക്ഷൻ ഓഫീസർക്ക് മാത്രമേ വിസ്ഡം ഗൈഡ് ഉപയോഗിക്കാൻ കഴിയൂ. നിലവിലുള്ള മറ്റു അഡ്മിന്മാർക്ക് ഒന്നു ഉപയോഗിക്കാൻ കഴിയില്ല. എന്നാൽ മെമ്പർഷിപ്പ് ആവശ്യത്തിനുവേണ്ടി സാധ്യത ലിസ്റ്റിൽ(കരട് ലിസ്റ്റ്)  ഉൾപ്പെടുത്തിയ എല്ലാവർക്കും ഗൈഡ് ഉപയോഗിക്കാം.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion08">
                           8. മെമ്പർഷിപ്പ് ചേരുന്നവർ ആപ്പ് ഉപയോഗിക്കേണ്ടതുണ്ടോ?
                        </button>
                    </h2>
                    <div id="accordion08" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                          നിർബന്ധമായും വേണം. മെമ്പർഷിപ്പ് എടുക്കാൻ ഉദ്ദേശിക്കുന്നവർക്ക് അവരുടെ മൊബൈൽ നമ്പർ ഉപയോഗിച്ച് സെപ്റ്റംബർ 1ന് ശേഷം ലോഗിൻ ചെയ്യാൻ സാധിക്കും. അങ്ങനെ ലോഗിൻ ചെയ്ത ശേഷം ആപ്പിൽ നൽകിയിരിക്കുന്ന വ്യക്തിഗത വിവരങ്ങൾ ഓരോ മെമ്പർമാരും പൂരിപ്പിച്ചു നൽകണം.  അങ്ങനെ പൂരിപ്പിച്ച് നൽകുന്നവർക്ക് മാത്രമാണ് മെമ്പർഷിപ്പ് ലഭിക്കുക. 
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion09">
                            9. സെപ്റ്റംബർ ഒന്നിന് ശേഷം മൊബൈൽ നമ്പർ ഉപയോഗിച്ച് ലോഗിൻ ചെയ്യാൻ സാധിക്കുന്നില്ലെങ്കിൽ മെമ്പർമാർ എന്ത് ചെയ്യണം? 
                        </button>
                    </h2>
                    <div id="accordion09" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         സെപ്റ്റംബർ ഒന്നിന് ശേഷം ലോഗിൻ ചെയ്യാൻ സാധിക്കുന്നില്ലെങ്കിൽ അവരുടെ മൊബൈൽ നമ്പർ കരട് ലിസ്റ്റിൽ ചേർത്തിട്ടില്ല എന്നാണ് മനസ്സിലാക്കേണ്ടത്. അല്ലെങ്കിൽ തെറ്റായ മൊബൈൽ നമ്പർ ആയിരിക്കും ചേർത്തിട്ടുണ്ടാവുക. അവർ ശാഖ അഡ്മിനെ ബന്ധപ്പെട്ട് കരട്  ലിസ്റ്റിലുള്ള അവരുടെ പേരും നമ്പറും ശരിയാണ് എന്ന് ഉറപ്പുവരുത്തുക.  അങ്ങനെ ചേർത്തു കഴിഞ്ഞാൽ അവർക്കും ആപ്പിൽ കയറാൻ സാധിക്കും. 
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion10">
                        10. അഡ്മിന്മാർ അവരുടെ ബയോഡാറ്റ ഇപ്പോൾ തന്നെ നൽകേണ്ടതുണ്ടോ?
                        </button>
                    </h2>
                    <div id="accordion10" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                          ഇല്ല. അഡ്മിൻമാരും മെമ്പർഷിപ്പ് എടുക്കുന്നവരും സെപ്റ്റംബർ ഒന്നിന് ശേഷം അവരുടെ ബയോഡാറ്റ പൂരിപ്പിച്ചാൽ മതിയാകും. 
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion11">
                           11. ഇലക്ഷൻ ഓഫീസർമാരെ വിസ്ഡം ഗൈഡിൽ ചേർക്കേണ്ടത് ആരാണ്?
                        </button>
                    </h2>
                    <div id="accordion11" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                          തൊട്ടുമുകളിലുള്ള ഇലക്ഷൻ ഓഫീസറാണ് താഴെയുള്ള ഓഫീസറെ വിസ്ഡം ഗൈഡിൽ ചേർക്കേണ്ടത്.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion12">
                          12. എന്താണ് കരട് ലിസ്റ്റ്?
                        </button>
                    </h2>
                    <div id="accordion12" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                          ശാഖയിൽ എല്ലാ ഘടകത്തിന്റെയും മെമ്പർഷിപ്പ് എടുക്കാൻ സാധ്യതയുള്ള ആളുകളുടെ ലിസ്റ്റ് ആണ് കരട് ലിസ്റ്റ്. ഈ ലിസ്റ്റിനു പുറത്തുള്ള ആർക്കുംമെമ്പർഷിപ്പ് എടുക്കാൻ കഴിയില്ല.എന്നാൽ ലിസ്റ്റിലുള്ള എല്ലാവരും മെമ്പർഷിപ്പ് എടുക്കണം എന്നില്ല. ശാഖ ഇലക്ഷൻ ഓഫീസറുടെ നേതൃത്വത്തിൽ നിലവിലുള്ള ശാഖാ സംയുക്ത യോഗം ചേർന്നാണ്ഈ ലിസ്റ്റ് തയ്യാറാക്കേണ്ടത്.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion13">
                           13. കരട് ലിസ്റ്റ് എവിടെയാണ് തയ്യാറാക്കേണ്ടത്?
                        </button>
                    </h2>
                    <div id="accordion13" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                          വിസ്ഡം ഗൈഡിലാണ് കരട് ലിസ്റ്റ് തയ്യാറാക്കേണ്ടത്. സൗകര്യത്തിനു വേണ്ടി ഇപ്പോൾ തന്നെ കരട് ലിസ്റ്റ് കടലാസിൽ തയ്യാറാക്കി വെക്കാം. ഇതിനുള്ള ഫോം മേൽഘടകത്തിൽ ലഭിക്കും.

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion14">
                           14. നിലവിലുള്ള മെമ്പർമാർ വീണ്ടും അപേക്ഷ നൽകണോ?

                        </button>
                    </h2>
                    <div id="accordion14" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         വേണം, ഒപ്പിട്ട അപേക്ഷ അവരും നൽകണം. എന്നാൽ,ബയോഡാറ്റ പുതിയ കാര്യങ്ങൾ മാത്രം ചേർത്ത് എഡിറ്റ് ചെയ്താൽ മതി.

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion15">
                          15. നിലവിലുള്ള മെമ്പർക്ക് മറ്റൊരു ശാഖയിലേക്ക് മാറാൻ കഴിയുമോ?
                        </button>
                    </h2>
                    <div id="accordion15" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         കഴിയും. അവരുടെ പേര് പുതിയ ശാഖയിലെ സാധ്യത ലിസ്റ്റിൽ ഉണ്ടാവണം എന്ന് മാത്രം. 

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion16">
                          16. ബയോഡാറ്റ ആണോ മെമ്പർഷിപ്പ് ഫോം ആണോ ആദ്യം നൽകേണ്ടത്?
                        </button>
                    </h2>
                    <div id="accordion16" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         ബയോഡാറ്റ നേരത്തെ പൂരിപ്പിക്കാൻ കഴിയും. ബയോഡാറ്റ പൂരിപ്പിച്ചവർക്ക് മാത്രം  മെമ്പർഷിപ്പ് ഫോം നൽകുന്നതായിരിക്കും ഉചിതം. മെമ്പർഷിപ്പ് ഫോം ഒപ്പിട്ടു നൽകുമ്പോൾ മെമ്പർഷിപ്പ് പൂർത്തിയായതായി അദ്ദേഹം വിചാരിക്കാൻ സാധ്യതയുണ്ട്.

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion17">
                           17. ബയോഡാറ്റയും മെമ്പർഷിപ്പ് ഫോം തമ്മിലുള്ള വ്യത്യാസം എന്താണ്? 
                        </button>
                    </h2>
                    <div id="accordion17" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         വിസ്‌ഡം ഗൈഡ് ആപ്പിൽ മെമ്പർമാർ വ്യക്തിഗത വിവരങ്ങൾ പൂരിപ്പിക്കുന്നതിനെയാണ് ബയോഡാറ്റ എന്നതുകൊണ്ട് ഉദ്ദേശിക്കുന്നത്. എന്നാൽ ശാഖയിൽ നിന്നും ലഭിക്കുന്ന പ്രിൻറ് ചെയ്ത ഫോമിനെയാണ് മെമ്പർഷിപ്പ് ഫോം എന്ന് പറയുന്നത്. ഗൈഡ് ആപ്പിൽ ബയോഡാറ്റ  പൂരിപ്പിക്കുന്നതോടൊപ്പം തന്നെ ശാഖയിൽ നിന്നും ലഭിക്കുന്ന പ്രിൻറ് ചെയ്ത മെമ്പർഷിപ്പ് ഫോം പൂരിപ്പിച്ച് നൽകുകയും വേണം. 

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion18">
                          18. ബയോഡാറ്റ പൂരിപ്പിക്കാത്ത ഒരാളുടെ മെമ്പർഷിപ്പ് അംഗീകരിക്കാമോ?
                        </button>
                    </h2>
                    <div id="accordion18" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         ഇല്ല, ബയോഡാറ്റയും മെമ്പർഷിപ്പ് ഫോറവും ലഭിച്ചാൽ മാത്രമേ മെമ്പർഷിപ്പ് അംഗീകരിക്കുകയുള്ളൂ.

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion19">
                         19. എങ്കിൽ ബയോഡാറ്റ മാത്രം നൽകിയാൽ പോരേ?
                        </button>
                    </h2>
                    <div id="accordion19" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         നിയമപരമായ കാര്യങ്ങൾക്ക് വേണ്ടി ഒപ്പിട്ട രേഖകൾ സംഘടനയ്ക്ക് ആവശ്യമുണ്ട്. അതുകൊണ്ടാണ് ഒപ്പിട്ട ഫോം വാങ്ങുന്നത്.

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion20">
                           20. പ്രവാസികളും കേരളത്തിന് പുറത്തുള്ളവരും മെമ്പർഷിപ്പ് എടുക്കേണ്ടത് എങ്ങിനെ? 
                        </button>
                    </h2>
                    <div id="accordion20" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                          പ്രവാസികളും കേരളത്തിന് പുറത്തുള്ളവരും അവരുടെ നാട്ടിലെ ശാഖയിലാണ് മെമ്പർഷിപ്പ് എടുക്കേണ്ടത്. അതിനായി പ്രവാസികളുടെ പേരും നമ്പറും നാട്ടിലെ ശാഖയിലെ അഡ്മിൻ സാധ്യത ലിസ്റ്റിലേക്ക് (കരട് ലിസ്റ്റ്) ചേർക്കണം. ഇത് അഡ്മിനെ കൊണ്ട് ചെയ്യിക്കാൻ ശ്രദ്ധിക്കുക.  ശേഷം ബയോഡാറ്റ പൂരിപ്പിക്കുന്ന സമയത്ത് പ്രവാസികളും wisdom guide ആപ്പ് ലോഗിൻ ചെയ്ത് ബയോഡാറ്റ പൂരിപ്പിക്കണം. അതിൻറെ കൂടെ ശാഖയിൽ നിന്നും ലഭിക്കുന്ന മെമ്പർഷിപ്പ് ഫോമും പൂരിപ്പിക്കണം. ഈ മെമ്പർഷിപ്പ് ഫോം പ്രവാസികൾക്ക് ആപ്പിൽ തന്നെയാണ് ലഭിക്കുക. അവർ മെമ്പർഷിപ്പ് ഫോമും ആപ്പിൽ തന്നെ പൂരിപ്പിച്ച് ഒപ്പിട്ട് സബ്മിറ്റ് ചെയ്യുക. 

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion21">
                           21. പ്രവാസികൾ എങ്ങനെയാണ് ഒപ്പിട്ട ഫോം നൽകുന്നത്? അവർക്ക് ബയോഡാറ്റ മാത്രം മതിയോ?
                        </button>
                    </h2>
                    <div id="accordion21" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         പ്രവാസികൾക്ക് ബയോഡാറ്റയും  മെമ്പർഷിപ്പ് ഫോറവും വിസ്ഡം ഗൈഡിൽ തന്നെ ലഭിക്കും. അവർ മെമ്പർഷിപ്പ് ഫോം പൂരിപ്പിച്ച് ഫോണിലെ ടച്ച് ഉപയോഗിച്ച് ഒപ്പ് വെക്കണം. അവർ അവരുടെ നാട്ടിലെ ശാഖകളിൽ തന്നെ മെമ്പർഷിപ് എടുക്കാൻ ശ്രദ്ധിക്കണം. 

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion22">
                          22. പ്രവാസികളുടെ മെമ്പർഷിപ്പ് ഫോം എങ്ങനെയാണ് ശാഖ ഇലക്ഷൻ ഓഫീസർക്ക് ലഭിക്കുക?
                        </button>
                    </h2>
                    <div id="accordion22" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         പ്രവാസികളുടെ മെമ്പർഷിപ്പ് ഫോം അവരുടെ നാട്ടിലെ ശാഖ ഇലക്ഷൻ ഓഫീസർക്ക് വിസ്ഡം ഗൈഡിൽ ലഭിക്കും. ഇത് പ്രിന്റ് എടുത്ത് മറ്റു അപേക്ഷകളുടെ കൂടെ കൈകാര്യം ചെയ്യണം.

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion23">
                          23. ബയോഡാറ്റയിൽ ഫോട്ടോ നിർബന്ധമാണോ?
                        </button>
                    </h2>
                    <div id="accordion23" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                        വനിതകൾ ഒഴികെ എല്ലാവർക്കും ഫോട്ടോ നിർബന്ധമാണ്.വ്യക്തികളെ പെട്ടെന്ന് തിരിച്ചറിയാൻ ഇതു വലിയ സഹായകമായിരിക്കും.

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion24">
                          24. ഫോൺ ഇല്ലാത്ത ഒരാളെ എങ്ങനെ വിസ്ഡം ഗൈഡിൽ ചേർക്കും?
                        </button>
                    </h2>
                    <div id="accordion24" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                        ഇദ്ദേഹത്തിന്റെ കുടുംബത്തിലെ മറ്റൊരു അംഗത്തിന്റെ ഫോണിൽ വിസ്ഡം ഗൈഡ് ഇൻസ്റ്റാൾ ചെയ്യുകയും ശേഷം ബയോഡാറ്റ പൂരിപ്പിക്കുകയും വേണം.

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion25">
                         25. ഐഫോൺ ഉപയോഗിക്കുന്നവർക്ക് വിസ്ഡം ഗൈഡ് ലഭിക്കുമോ?
                        </button>
                    </h2>
                    <div id="accordion25" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                       എല്ലാ ഫോണുകളിലും ഉപയോഗിക്കാവുന്ന രീതിയിലാണ് ഇനി മുതൽ വിസ്ഡം ഗൈഡ് ലഭ്യമാവുക. അത് കൊണ്ട് ഐ ഫോണിലും ഇത് ഉപയോഗിക്കാം. 

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion26">
                          26. വിസ്ഡം ഗൈഡിൽ ടെക്നിക്കൽ പ്രശ്നങ്ങൾ ഉണ്ടായാൽ ആരെയാണ് ബന്ധപ്പെടേണ്ടത്?
                        </button>
                    </h2>
                    <div id="accordion26" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                         ഇതിനുവേണ്ടി ഒരു ഓൺലൈൻ ഹെൽപ് ഡെസ്ക് സംവിധാനം ഒരുക്കുന്നുണ്ട്. ബന്ധപ്പെടാവുന്ന ഒരു ഫോൺ നമ്പർ കൂടി പിന്നീട് നൽകും.

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion27">
                         27. വിസ്ഡം ഗൈഡ് സംബന്ധിച്ച് പരിശീലനം ലഭിക്കുമോ?
                        </button>
                    </h2>
                    <div id="accordion27" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                        സംസ്ഥാനതലത്തിൽ ഒരു പരിശീലനം നൽകും. ഇതിൽ ബന്ധപ്പെട്ട എല്ലാവരെയും പങ്കെടുപ്പിക്കാൻ ശ്രദ്ധിക്കണം.

                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#accordion28">
                        28. പുതിയ ശാഖകൾ വിസ്ഡം ഗൈഡിൽ ഉണ്ടാകുമോ?
                        </button>
                    </h2>
                    <div id="accordion28" class="accordion-collapse collapse" data-bs-parent="#accordionExample1">
                        <div class="accordion-body">
                       മണ്ഡലങ്ങൾ പുതിയ ശാഖയുടെ പേര് വിവരങ്ങൾ നൽകുന്നതനുസരിച്ച് പുതിയ ശാഖകളും ലഭിക്കും.എത്രയും വേഗം പുതിയ വിവരങ്ങൾ മണ്ഡലങ്ങൾ നൽകുക. പിന്നീട് ചേർക്കാൻ പ്രയാസമായിരിക്കും.

                        </div>
                    </div>
                </div>

            </div>
        </div>
</template>
<script>
export default {};
</script>
<style scoped>
.accordion-button{
    font-weight: 700 !important;
}
.imaged{
    width:60px !important;
}
</style>