<template>
  <div id="app">
    <div id="appCapsule">
      <app-header v-if="this.$route.name != 'OTP'" :page_header="page_header">
      </app-header>
      <router-view :key="$route.path"/>
    </div>
    <app-footer-menu v-if="this.$route.name != 'OTP' && this.$route.name != 'Login'">
    </app-footer-menu>
    <app-side-menu></app-side-menu>
  </div>
</template>

<script>
import AppHeader from './views/layouts/AppHeader.vue';
import AppFooterMenu from './views/layouts/AppFooterMenu.vue';
import AppSideMenu from './views/layouts/AppSideMenu.vue';
export default {
  components: {
    AppHeader,
    AppFooterMenu,
    AppSideMenu
  },
  data() {
    return {
      'page_header': null,
    }
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_MODERATOR');
      }

      return false;
    }
  },
  mounted() {
    this.page_header = null;
  },
  methods: {
    updateParent(variable) {
      this.page_header = variable;
    },
    logOut() {
      this.$store.dispatch('auth/logout');
      location.replace('/');
    }
  },
};
</script>
