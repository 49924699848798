<template>
    <!-- App Capsule -->
    <ul class="listview image-listview inset text mt-2 pt-2">
        <li>
            <a href="javascript:void(0)" class="item">
                <!-- <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image"> -->
                <div class="in">
                    <div>
                        <header>Name</header>
                      <b>  {{ currentUser.name }}</b>
                        <!-- <footer>Total members : 100</footer> -->
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" class="item">
                <!-- <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image"> -->
                <div class="in">
                    <div>
                        <header>Mobile Number</header>
                      <b>  {{ currentUser.username }}</b>
                        <!-- <footer>Total members : 100</footer> -->
                    </div>
                </div>
            </a>
        </li>
        <li>
            <router-link :to="'set-password'">
                <a href="javascript:void(0)" class="item">
                    <!-- <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image"> -->
                    <div class="in">
                        <div>
                            <header>Reset Pin</header>
                         <b>   Click to change your pin.</b>
                            <!-- <footer></footer> -->
                        </div>
                    </div>
                </a>
            </router-link>
        </li>
        <li>
            <a href="javascript:void(0)" class="item">
                <!-- <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image"> -->
                <div class="in">
                    <div>
                        <header>App Version</header>
                      <b>  2.0 </b>
                        <!-- <footer>Total members : 100</footer> -->
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" class="item" @click="logout">
                <!-- <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" class="image"> -->
                <div class="in">
                    <div>
                        <!-- <header>Logout</header> -->
                      <b>  Logout </b>
                        <!-- <footer>Total members : 100</footer> -->
                    </div>
                </div>
            </a>
        </li>
    </ul>


    <!-- * App Capsule -->
</template>
<script>
import AuthService from "../services/auth.service";
export default {
    name: 'Profile',
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.$root.updateParent("My Profile");
    },
    methods: {
        logout() {
            AuthService.logout();
            localStorage.activeMenu = 'Home';
            location.replace('/');
        }
    },
};
</script>
<style>
.col-8 {
    margin-top: -25px !important;
}

.listview {
    font-size: 14px !important;
}

.listview li {
    min-height: 25px !important;
}
</style>