<template>

  <div class="home">
    <!-- App Capsule -->
    <!-- <div id="appCapsule"> -->
    <div class="section full mb-3 pt-2">
      <carousel :items-to-show="1">
        <slide v-for="slide in 5" :key="slide" autoplay="1500">
          <div class="card card-slider" v-if="slide == 1">
            <img src="assets/img/banners/help.jpeg" class="card-img-top" alt="image">
            <div class="card-body">
              <h5 class="card-title">ടെക്നിക്കൽ പ്രശ്നങ്ങളും സംശയങ്ങൾക്കും ഇപ്പോൾ ഉടൻ പരിഹാരം
                <br> <a href="https://wa.me/919526221177"><b class="blue-head">ഇവിടെ ക്ലിക്ക് ചെയ്യുക</b></a>
              </h5>
              <p class="card-text">
                വിസ്‌ഡം ഗൈഡുമായി ബന്ധപ്പെട്ട നിങ്ങളുടെ സംശയങ്ങൾക്കും പ്രശ്നങ്ങൾക്കും ഉടൻ  പരിഹാരം ലഭിക്കാൻ <b>+919526221177</b> എന്ന വാട്സ്ആപ്പ് നമ്പറിൽ ബന്ധപ്പെടാവുന്നതാണ്.
              </p>
            </div>
          </div>
          <!-- <div class="card card-slider" v-if="slide == 1">
            <img src="assets/img/banners/1.jpeg" class="card-img-top" alt="image">
            <div class="card-body">
              <h5 class="card-title">മണ്ഡലം ഇലക്ഷൻ ഓഫീസർമാരുടെ നിയമനം
                <br> <b class="blue-head"> Last Date: ആഗസ്റ്റ് 25 </b>
              </h5>
              <p class="card-text">
                മണ്ഡലം ഇലക്ഷൻ ഓഫീസർമാർ അഥവാ അഡ്മിന്മാരുടെ പേരും മൊബൈൽ നമ്പറും ജില്ലാ അഡ്മിന്മാർ ആഗസ്റ്റ്25ന് മുമ്പ്
                ഗൈഡിലേക്ക് ചേർക്കുക. ഓരോ മണ്ഡലത്തിന്റെ പേര് സെലക്ട് ചെയ്ത ശേഷം അതാത് മണ്ഡലത്തിലെ അഡ്മിന്മാരുടെ
                വിവരങ്ങൾ ചേർക്കുക. മണ്ഡലം അഡ്മിന്മാർക്കുള്ള ട്രെയിനിങ്: ആഗസ്റ്റ്25
              </p>
            </div>
          </div> -->
          <div class="card card-slider" v-if="slide == 2">
            <img src="assets/img/banners/2.jpeg" class="card-img-top" alt="image">
            <div class="card-body">
              <h5 class="card-title">ശാഖ ഇലക്ഷൻ ഓഫീസർമാരുടെ നിയമനം
                <br><b class="blue-head"> Last Date: ആഗസ്റ്റ് 28 </b>
              </h5>
              <p class="card-text">
                മണ്ഡലം അഡ്മിന്മാർ അഥവാ മണ്ഡലം ഇലക്ഷൻ ഓഫീസർമാർ ആണ് ശാഖയിലെ ഇലക്ഷൻ ഓഫീസർമാരെ വിസ്‌ഡം ഗൈഡിലേക്ക്
                ചേർക്കേണ്ടത്. ആഗസ്റ്റ് 28നു മുമ്പ് ചേർക്കൽ പൂർത്തിയാക്കണം. ശാഖ ഓഫീസർമാരുടെ യോഗം ആഗസ്റ്റ് 28ന്
                നടക്കും
              </p>
            </div>
          </div>
          <div class="card card-slider" v-if="slide == 3">
            <img src="assets/img/banners/3.jpeg" class="card-img-top" alt="image">
            <div class="card-body">
              <h5 class="card-title">ശാഖയിൽ മെമ്പർഷിപ്പ് നൽകാനുള്ളവരുടെ കരട് ലിസ്റ്റ് ഗൈഡിലേക്ക് ചേർക്കൽ
                <br> <b class="blue-head">Last Date: ആഗസ്റ്റ് 31 </b>
              </h5>
              <p class="card-text">
                ശാഖ അഡ്മിന്മാർ ആണ് ശാഖയിൽ നിന്നും മെമ്പർഷിപ്പ് നൽകാൻ ഉദ്ദേശിക്കുന്നവരുടെ കരട് ലിസ്റ്റ് വിസ്‌ഡം ഗൈഡ്
                ആപ്പിലേക്ക് ചേർക്കേണ്ടത്. ആഗസ്റ്റ് 31ന് മുമ്പ് ഇത് പൂർത്തിയാക്കുക.
              </p>
            </div>
          </div>
          <div class="card card-slider" v-if="slide == 4">
            <img src="assets/img/banners/4.jpeg" class="card-img-top" alt="image">
            <div class="card-body">
              <h5 class="card-title">മെമ്പർഷിപ്പ് ലഭിക്കേണ്ടവർ സെപ്റ്റംബർ 1 മുതൽ ആപ്പിൽ ബയോഡാറ്റ നൽകണം</h5>
              <p class="card-text">
                മെമ്പർഷിപ്പ് ലഭിക്കേണ്ടവർ നിർബന്ധമായും ആപ്പ് ലോഗിൻ ചെയ്ത് ബയോഡാറ്റ പൂരിപ്പിച്ചു നൽകണം. സെപ്റ്റംബർ 1
                മുതൽ 15 വരെയാണ് ഇതിനുള്ള അവസരം.
              </p>
            </div>
          </div>
          <div class="card card-slider" v-if="slide == 5">
            <img src="assets/img/banners/5.jpeg" class="card-img-top" alt="image">
            <div class="card-body">
              <h5 class="card-title">മെമ്പർഷിപ്പ് ലഭിക്കേണ്ടവർ ഗൈഡിൽ ബയോഡാറ്റ ചേർക്കൽ
                <br> <b class="blue-head">Last Date: സെപ്റ്റംബർ15 </b>
              </h5>
              <p class="card-text">
                കരട് ലിസ്റ്റിൽ ചേർക്കപ്പെട്ട എല്ലാ ആളുകൾക്കും വിസ്‌ഡം ഗൈഡ് ആപ്പിൽ ലോഗിൻ ചെയ്ത് അവരുടെ ബയോഡാറ്റ
                സെപ്റ്റംബർ1 മുതൽ പൂരിപ്പിക്കാവുന്നതാണ്. മെമ്പർഷിപ്പ് ലഭിക്കാൻ മെമ്പർഷിപ് ഫോമിനു പുറമേ ബയോഡാറ്റ
                കൂടി പൂരിപ്പിക്കണം. ഇത് ചെയ്തു തീർക്കേണ്ട അവസാന തീയതി: സെപ്റ്റംബർ 15
              </p>
            </div>
          </div>
        </slide>
        <template #addons>
          <pagination />
        </template>
      </carousel>
      <!-- <carousel :perPage="1">
        <slide class="p-2">
          <b-card title="Card Title 1" img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top
            tag="article">
            <b-card-text>
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </b-card-text>
            <b-button href="#" variant="primary">Go somewhere</b-button>
          </b-card>
        </slide>
        <slide class="p-2">
          <b-card title="Card Title 2" img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top
            tag="article">
            <b-card-text>
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </b-card-text>

            <b-button href="#" variant="primary">Go somewhere</b-button>
          </b-card>
        </slide>
        <slide class="p-2">
          <b-card title="Card Title 3" img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top
            tag="article">
            <b-card-text>
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </b-card-text>

            <b-button href="#" variant="primary">Go somewhere</b-button>
          </b-card>
        </slide>
        <slide class="p-2">
          <b-card title="Card Title 4" img-src="https://picsum.photos/600/300/?image=25" img-alt="Image" img-top
            tag="article">
            <b-card-text>
              Some quick example text to build on the card title and make up the bulk of the card's content.
            </b-card-text>

            <b-button href="#" variant="primary">Go somewhere</b-button>
          </b-card>
        </slide>
      </carousel> -->
    </div>
    <div class="section full mb-0 pt-0">

      <!-- carousel single -->
      <!-- <div class="carousel-single splide">
        <div class="splide__track">
          <ul class="splide__list">

            <li class="splide__slide">
              <div class="card">
                <img src="assets/img/banners/1.jpeg" class="card-img-top" alt="image">
                <div class="card-body">
                  <h5 class="card-title">മണ്ഡലം ഇലക്ഷൻ ഓഫീസർമാരുടെ നിയമനം
                    <br> <b class="blue-head"> Last Date: ആഗസ്റ്റ് 25 </b>
                  </h5>
                  <p class="card-text">
                    മണ്ഡലം ഇലക്ഷൻ ഓഫീസർമാർ അഥവാ അഡ്മിൻ മാരുടെ പേരും മൊബൈൽ നമ്പറും ജില്ലാ അഡ്മിന്മാർ ആഗസ്റ്റ്25ന് മുമ്പ്
                    ഗൈഡിലേക്ക് ചേർക്കുക. ഓരോ മണ്ഡലത്തിന്റെ പേര് സെലക്ട് ചെയ്ത ശേഷം അതാത് മണ്ഡലത്തിലെ അഡ്മിന്മാരുടെ
                    വിവരങ്ങൾ ചേർക്കുക. മണ്ഡലം അഡ്മിന്മാർക്കുള്ള ട്രെയിനിങ്: ആഗസ്റ്റ്25
                  </p>
                </div>
              </div>
            </li>

            <li class="splide__slide">
              <div class="card">
                <img src="assets/img/banners/2.jpeg" class="card-img-top" alt="image">
                <div class="card-body">
                  <h5 class="card-title">ശാഖ ഇലക്ഷൻ ഓഫീസർമാരുടെ നിയമനം
                    <br><b class="blue-head"> Last Date: ആഗസ്റ്റ് 28 </b>
                  </h5>
                  <p class="card-text">
                    മണ്ഡലം അഡ്മിന്മാർ അഥവാ മണ്ഡലം ഇലക്ഷൻ ഓഫീസർമാർ ആണ് ശാഖയിലെ ഇലക്ഷൻ ഓഫീസർമാരെ വിസ്‌ഡം ഗൈഡിലേക്ക്
                    ചേർക്കേണ്ടത്. ആഗസ്റ്റ് 28നു മുമ്പ് ചേർക്കൽ പൂർത്തിയാക്കണം. ശാഖ ഓഫീസർമാരുടെ യോഗം ആഗസ്റ്റ് 28ന്
                    നടക്കും
                  </p>
                </div>
              </div>
            </li>

            <li class="splide__slide">
              <div class="card">
                <img src="assets/img/banners/3.jpeg" class="card-img-top" alt="image">
                <div class="card-body">
                  <h5 class="card-title">ശാഖയിൽ മെമ്പർഷിപ്പ് നൽകാനുള്ളവരുടെ കരട് ലിസ്റ്റ് ഗൈഡിലേക്ക് ചേർക്കൽ
                    <br> <b class="blue-head">Last Date: ആഗസ്റ്റ് 31 </b>
                  </h5>
                  <p class="card-text">
                    ശാഖ അഡ്മിന്മാർ ആണ് ശാഖയിൽ നിന്നും മെമ്പർഷിപ്പ് നൽകാൻ ഉദ്ദേശിക്കുന്നവരുടെ കരട് ലിസ്റ്റ് വിസ്‌ഡം ഗൈഡ്
                    ആപ്പിലേക്ക് ചേർക്കേണ്ടത്. ആഗസ്റ്റ് 31ന് മുമ്പ് ഇത് പൂർത്തിയാക്കുക.
                  </p>
                </div>
              </div>
            </li>
            <li class="splide__slide">
              <div class="card">
                <img src="assets/img/banners/4.jpeg" class="card-img-top" alt="image">
                <div class="card-body">
                  <h5 class="card-title">മെമ്പർഷിപ്പ് ലഭിക്കേണ്ടവർ സെപ്റ്റംബർ 1 മുതൽ ആപ്പിൽ ബയോഡാറ്റ നൽകണം</h5>
                  <p class="card-text">
                    മെമ്പർഷിപ്പ് ലഭിക്കേണ്ടവർ നിർബന്ധമായും ആപ്പ് ലോഗിൻ ചെയ്ത് ബയോഡാറ്റ പൂരിപ്പിച്ചു നൽകണം. സെപ്റ്റംബർ 1
                    മുതൽ 15 വരെയാണ് ഇതിനുള്ള അവസരം.
                  </p>
                </div>
              </div>
            </li>
            <li class="splide__slide">
              <div class="card">
                <img src="assets/img/banners/5.jpeg" class="card-img-top" alt="image">
                <div class="card-body">
                  <h5 class="card-title">മെമ്പർഷിപ്പ് ലഭിക്കേണ്ടവർ ഗൈഡിൽ ബയോഡാറ്റ ചേർക്കൽ
                    <br> <b class="blue-head">Last Date: സെപ്റ്റംബർ15 </b>
                  </h5>
                  <p class="card-text">
                    കരട് ലിസ്റ്റിൽ ചേർക്കപ്പെട്ട എല്ലാ ആളുകൾക്കും വിസ്‌ഡം ഗൈഡ് ആപ്പിൽ ലോഗിൻ ചെയ്ത് അവരുടെ ബയോഡാറ്റ
                    സെപ്റ്റംബർ1 മുതൽ പൂരിപ്പിക്കാവുന്നതാണ്. മെമ്പർഷിപ്പ് ലഭിക്കാൻ മെമ്പർഷിപ്പ് ഫോമിന്റെ പുറമേ ബയോഡാറ്റ
                    കൂടി പൂരിപ്പിക്കണം. ഇത് ചെയ്തു തീർക്കേണ്ട അവസാന തീയതി: സെപ്റ്റംബർ 15
                  </p>
                </div>
              </div>
            </li>

          </ul>
        </div>
      </div> -->
      <!-- * carousel single -->

    </div>

    <div class="section mt- mb-2">
      <div class="section-title">ഈ ദിനങ്ങൾ ശ്രദ്ധിക്കുക</div>
      <div class="card">
        <!-- timeline -->
        <div class="timeline timed ms-1 me-2">

          <div class="item">
            <span class="time">Aug: 21</span>
            <div class="dot bg-primary"></div>
            <div class="content">
              <h4 class="title">സംസ്ഥാന ഇലക്ഷൻ ഓഫീസർമാരുടെ നിയമനം</h4>
              <!-- <div class="text">You sent $50 to John</div> -->
            </div>
          </div>
          <div class="item">
            <span class="time">Aug 22</span>
            <div class="dot"></div>
            <div class="content">
              <h4 class="title">ജില്ലാ ഇലക്ഷൻ ഓഫീസർമാരുടെ നിയമനം പൂർത്തിയാക്കൽ</h4>
              <!-- <div class="text">Your ID has been approved.</div> -->
            </div>
          </div>
          <div class="item">
            <span class="time">Aug 22 to 25</span>
            <div class="dot bg-info"></div>
            <div class="content">
              <h4 class="title">മണ്ഡലം ഇലക്ഷൻ ഓഫീസർമാരുടെ നിയമനം പൂർത്തിയാക്കൽ</h4>
              <!-- <div class="text">*** 5039</div> -->
            </div>
          </div>
          <div class="item">
            <span class="time">Aug 25 </span>
            <div class="dot bg-primary"></div>
            <div class="content">
              <h4 class="title">മണ്ഡലം ഇലക്ഷൻ ഓഫീസർമാർക്കുള്ള ട്രെയിനിങ്</h4>
              <!-- <div class="text">You sent $50 to John</div> -->
            </div>
          </div>
          <div class="item">
            <span class="time">Aug 25 to 28</span>
            <div class="dot"></div>
            <div class="content">
              <h4 class="title">ശാഖ ഇലക്ഷൻ ഓഫീസർമാരുടെ നിയമനം പൂർത്തിയാക്കൽ</h4>
              <!-- <div class="text">Your ID has been approved.</div> -->
            </div>
          </div>
          <div class="item">
            <span class="time">Aug 28</span>
            <div class="dot bg-info"></div>
            <div class="content">
              <h4 class="title">ശാഖ ഇലക്ഷൻ ഓഫീസർമാർക്കുള്ള ട്രെയിനിങ് </h4>
              <!-- <div class="text">*** 5039</div> -->
            </div>
          </div>
          <div class="item">
            <span class="time">Aug 28 to 31</span>
            <div class="dot"></div>
            <div class="content">
              <h4 class="title">ശാഖയിൽ മെമ്പർഷിപ്പ് നൽകാനുള്ളവരുടെ കരട് ലിസ്റ്റ് ഗൈഡിലേക്ക് ചേർക്കൽ</h4>
              <!-- <div class="text">Your ID has been approved.</div> -->
            </div>
          </div>
          <div class="item">
            <span class="time">Sept 1 to 15</span>
            <div class="dot bg-info"></div>
            <div class="content">
              <h4 class="title">മെമ്പർഷിപ്പ് ലഭിക്കേണ്ടവർ ഗൈഡിൽ ബയോഡാറ്റ പൂരിപ്പിക്കൽ</h4>
              <!-- <div class="text">*** 5039</div> -->
            </div>
          </div>
        </div>
        <!-- * timeline -->
      </div>
    </div>
    <!-- <div class="section full pt-2"> -->



    <!-- carousel single -->
    <!-- <div class="carousel-single splide">
        <div class="splide__track">
          <ul class="splide__list">
            <li class="splide__slide">
              <div class="card">
                <img src="assets/img/sample/photo/wide2.jpg" class="card-img-top" alt="image">
                <div class="card-body">
                  <h5 class="card-title">Carousel Item</h5>
                  <p class="card-text">
                    Some quick example text to build on the card title and make up the bulk
                    of the card's content.
                  </p>
                </div>
              </div>
            </li>

            <li class="splide__slide">
              <div class="card">
                <img src="assets/img/sample/photo/wide3.jpg" class="card-img-top" alt="image">

              </div>
            </li>

            <li class="splide__slide">
              <div class="card">
                <img src="assets/img/sample/photo/wide4.jpg" class="card-img-top" alt="image">

              </div>
            </li>

          </ul>
        </div>
      </div> -->
    <!-- * carousel single -->
    <!-- <Splide :options="{ rewind: true }" aria-label="My Favorite Images">
            <SplideSlide>
              <img src="assets/img/banners/1.jpeg" alt="Sample 1">
            </SplideSlide>
            <SplideSlide>
              <img src="assets/img/banners/2.jpeg" alt="Sample 2">
            </SplideSlide>
      </Splide> -->

    <!-- </div> -->
    <!-- <div class="section mt-2">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Wisdom Guide</h5>
            <p class="card-text">
              Welcome to wisdom guide.
            </p>
          </div>
        </div>
      </div> -->
    <!-- Wallet Card -->
    <!-- <div class="section wallet-card-section pt-1">
        <div class="wallet-card"> -->
    <!-- Balance -->
    <!-- <div class="balance"> -->
    <!-- <div class="left">
              <span class="title">Welcome to Wisdom Guide</span>
              <h1 class="total">10001</h1>
            </div> -->
    <!-- <div class="right">
              <a href="javascript:void(0)" class="button" data-bs-toggle="modal" data-bs-target="#depositActionSheet">
                <ion-icon name="add-outline"></ion-icon>
              </a>
            </div> -->
    <!-- </div> -->
    <!-- * Balance -->
    <!-- Wallet Footer -->
    <!-- <div class="wallet-footer">
            <div class="item">
              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#withdrawActionSheet">
                <div class="icon-wrapper bg-danger">
                  <ion-icon name="arrow-down-outline"></ion-icon>
                </div>
                <strong>Units</strong>
              </a>
            </div>
            <div class="item">
              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#sendActionSheet">
                <div class="icon-wrapper">
                  <ion-icon name="arrow-down-outline"></ion-icon>
                </div>
                <strong>Zonals</strong>
              </a>
            </div>
            <div class="item">
              <a href="javascript:void(0)">
                <div class="icon-wrapper bg-success">
                  <ion-icon name="arrow-down-outline"></ion-icon>
                </div>
                <strong>Districts</strong>
              </a>
            </div>
            <div class="item">
              <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#exchangeActionSheet">
                <div class="icon-wrapper bg-warning">
                  <ion-icon name="arrow-down-outline"></ion-icon>
                </div>
                <strong>Note</strong>
              </a>
            </div>

          </div> -->
    <!-- * Wallet Footer -->
    <!-- </div>
      </div> -->
    <!-- Wallet Card -->

    <!-- Deposit Action Sheet -->
    <div class="modal fade action-sheet" id="depositActionShee" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add Balance</h5>
          </div>
          <div class="modal-body">
            <div class="action-sheet-content">
              <form>
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="account1">From</label>
                    <select class="form-control custom-select" id="account1">
                      <option value="0">Savings (*** 5019)</option>
                      <option value="1">Investment (*** 6212)</option>
                      <option value="2">Mortgage (*** 5021)</option>
                    </select>
                  </div>
                </div>

                <div class="form-group basic">
                  <label class="label">Enter Amount</label>
                  <div class="input-group mb-2">
                    <span class="input-group-text" id="basic-addona1">$</span>
                    <input type="text" class="form-control" placeholder="Enter an amount" value="100">
                  </div>
                </div>


                <div class="form-group basic">
                  <button type="button" class="btn btn-primary btn-block btn-lg"
                    data-bs-dismiss="modal">Deposit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- * Deposit Action Sheet -->

    <!-- Withdraw Action Sheet -->
    <div class="modal fade action-sheet" id="withdrawActionShee" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Withdraw Money</h5>
          </div>
          <div class="modal-body">
            <div class="action-sheet-content">
              <form>
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="account2d">From</label>
                    <select class="form-control custom-select" id="account2d">
                      <option value="0">Savings (*** 5019)</option>
                      <option value="1">Investment (*** 6212)</option>
                      <option value="2">Mortgage (*** 5021)</option>
                    </select>
                  </div>
                </div>

                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="text11d">To</label>
                    <input type="email" class="form-control" id="text11d" placeholder="Enter IBAN">
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div class="form-group basic">
                  <label class="label">Enter Amount</label>
                  <div class="input-group mb-2">
                    <span class="input-group-text" id="basic-addonb1">$</span>
                    <input type="text" class="form-control" placeholder="Enter an amount" value="100">
                  </div>
                </div>

                <div class="form-group basic">
                  <button type="button" class="btn btn-primary btn-block btn-lg"
                    data-bs-dismiss="modal">Withdraw</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- * Withdraw Action Sheet -->

    <!-- Send Action Sheet -->
    <div class="modal fade action-sheet" id="sendActionShee" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Send Money</h5>
          </div>
          <div class="modal-body">
            <div class="action-sheet-content">
              <form>
                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="account2">From</label>
                    <select class="form-control custom-select" id="account2">
                      <option value="0">Savings (*** 5019)</option>
                      <option value="1">Investment (*** 6212)</option>
                      <option value="2">Mortgage (*** 5021)</option>
                    </select>
                  </div>
                </div>

                <div class="form-group basic">
                  <div class="input-wrapper">
                    <label class="label" for="text11">To</label>
                    <input type="email" class="form-control" id="text11" placeholder="Enter bank ID">
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div class="form-group basic">
                  <label class="label">Enter Amount</label>
                  <div class="input-group mb-2">
                    <span class="input-group-text" id="basic-addon1">$</span>
                    <input type="text" class="form-control" placeholder="Enter an amount" value="100">
                  </div>
                </div>

                <div class="form-group basic">
                  <button type="button" class="btn btn-primary btn-block btn-lg" data-bs-dismiss="modal">Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- * Send Action Sheet -->

    <!-- Exchange Action Sheet -->
    <div class="modal fade action-sheet" id="exchangeActionShee" tabindex="-1" role="dialog">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Exchange Money</h5>
          </div>
          <div class="modal-body">
            <div class="action-sheet-content">
              <form>
                <div class="row">
                  <div class="col-6">
                    <div class="form-group basic">
                      <div class="input-wrapper">
                        <label class="label" for="currency1">From</label>
                        <select class="form-control custom-select" id="currency1">
                          <option value="1">EUR</option>
                          <option value="2">USD</option>
                          <option value="3">AUD</option>
                          <option value="4">CAD</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group basic">
                      <div class="input-wrapper">
                        <label class="label" for="currency2">To</label>
                        <select class="form-control custom-select" id="currency2">
                          <option value="1">USD</option>
                          <option value="1">EUR</option>
                          <option value="2">AUD</option>
                          <option value="3">CAD</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="form-group basic">
                  <label class="label">Enter Amount</label>
                  <div class="input-group mb-2">
                    <span class="input-group-text" id="basic-addon2">$</span>
                    <input type="text" class="form-control" placeholder="Enter an amount" value="100">
                  </div>
                </div>



                <div class="form-group basic">
                  <button type="button" class="btn btn-primary btn-block btn-lg"
                    data-bs-dismiss="modal">Exchange</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- * Exchange Action Sheet -->

    <!-- Stats -->
    <!-- <div class="section">
        <div class="row mt-2">
          <div class="col-6">
            <div class="stat-box">
              <div class="title">Admins</div>
              <div class="value text-success">552</div>
            </div>
          </div>
          <div class="col-6">
            <div class="stat-box">
              <div class="title">Zonals</div>
              <div class="value text-danger">86</div>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6">
            <div class="stat-box">
              <div class="title">Units</div>
              <div class="value">53</div>
            </div>
          </div>
          <div class="col-6">
            <div class="stat-box">
              <div class="title">Members</div>
              <div class="value">120</div>
            </div>
          </div>
        </div>
      </div> -->
    <!-- * Stats -->

    <!-- app footer -->
    <!-- <div class="appFooter">
        <div class="footer-title">
          Copyright © Finapp 2021. All Rights Reserved.
        </div>
        Bootstrap 5 based mobile template.
      </div> -->
    <!-- * app footer -->

    <!-- </div> -->
    <!-- * App Capsule -->
  </div>



</template>
<script>
// import { Splide, SplideSlide } from '@splidejs/vue-splide';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination } from 'vue3-carousel';
export default {
  data() {
    return {
    };
  },
  methods: {
  },
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  mounted() {
    localStorage.activeMenu = 'Home';
  },
};
</script>
<style scoped>
.blue-head {
  color: blue;
}

.carousel__slide {
  width: 100% !important;
}

.card-slider {
  width: 90% !important;
}

.card-title {
  font-weight: 600 !important;
}
</style>